"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoPopUp = void 0;
const react_1 = __importStar(require("react"));
const VideoPopUp_elements_1 = require("./VideoPopUp.elements");
const PopUp_1 = require("@components/common/PopUp");
const Landing_1 = require("@app/routes/Landing");
const VideoPopUp_translations_1 = require("./VideoPopUp.translations");
const VideoPopUp = ({ title, video, url, onClose }) => {
    const language = (0, react_1.useContext)(Landing_1.LanguageContext);
    const t = VideoPopUp_translations_1.translations[language];
    return (react_1.default.createElement(VideoPopUp_elements_1.VideoPopUpWrap, null,
        react_1.default.createElement(PopUp_1.PopUp, { title: title, onClose: onClose },
            react_1.default.createElement("video", { controls: true, controlsList: "nodownload", muted: true, style: {
                    width: "100%",
                    maxWidth: "50vw",
                    borderRadius: "20px",
                    paddingTop: "25px",
                }, src: video }),
            react_1.default.createElement(VideoPopUp_elements_1.OpenButton, { href: url }, t.button))));
};
exports.VideoPopUp = VideoPopUp;
