"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        title: "Enter your email",
        input: {
            placeholder: "Enter your email",
        },
        validation: {
            required: "This field is required",
            "wrong-email": "Invalid email format",
            "send-fail": "An error occurred. Please, try again",
        },
        button: "Send me the terms of the referral program",
    },
    ru: {
        title: "Введите вашу почту",
        input: {
            placeholder: "Введите вашу почту",
        },
        validation: {
            required: "Поле обязательно для заполнения",
            "wrong-email": "Неправильный формат",
            "send-fail": "Произошла ошибка. Попробуйте снова",
        },
        button: "Выслать условия реферальной программы",
    },
};
