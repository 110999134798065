"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChartCustomTooltip = void 0;
const react_1 = require("react");
const useChartCustomTooltip = ({ initState }) => {
    const [tooltipState, setDonutsPopupState] = (0, react_1.useState)(initState);
    const closeTooltip = () => setDonutsPopupState((state) => (Object.assign(Object.assign({}, state), { show: false })));
    const openTooltip = ({ params }) => setDonutsPopupState(Object.assign(Object.assign({}, params), { show: true }));
    return {
        closeTooltip,
        openTooltip,
        tooltipState
    };
};
exports.useChartCustomTooltip = useChartCustomTooltip;
