"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        h1: "Why people choose EZSpeech?",
        content: {
            1: {
                h3: "Children",
                p: "EZSpeech turns sessions into an exciting game with interactive tasks and vivid visual elements. It makes the training process interesting and motivating, helping to achieve results in a fun and stress-free way.",
            },
            2: {
                h3: "Parents",
                p: "Unlike other applications, EZSpeech not only shows exercises, but also tells a child how to perform them correctly, as well as monitors the performance. Parents can be confident in the quality and effectiveness of sessions.",
            },
            3: {
                h3: "Speech Therapists",
                p: "EZSpeech significantly improves the effectiveness of therapy by providing detailed statistics and reports that help in choosing the Individual plan of care. It is a convenient tool for flexibly configuring exercises and tracking progress, maintaining communication with parents and simplifying the work of specialists.",
            },
        },
    },
    ru: {
        h1: "Почему выбирают EZSpeech?",
        content: {
            1: {
                h3: "Дети",
                p: "EZSpeech превращает занятия в увлекательную игру с интерактивными заданиями и яркими визуальными элементами. Оно делает процесс обучения интересным и мотивирующим, помогая достигать результатов весело и без стресса.",
            },
            2: {
                h3: "Родители",
                p: "В отличие от других приложений, EZSpeech не только показывает упражнения, но и подсказывает, как правильно их выполнять, а также контролирует их выполнение. Родители могут быть уверены в качестве и эффективности занятий.",
            },
            3: {
                h3: "Логопеды",
                p: "EZSpeech значительно повышает эффективность терапии, предоставляя подробную статистику и отчеты, которые помогают в подборе оптимального плана занятий. Это удобный инструмент для гибкой настройки упражнений и отслеживания прогресса, поддерживающий связь с родителями и упрощающий работу специалистов.",
            },
        },
    },
};
