"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        h1: "App for parents — Support for your child's speech development",
        mainContent: {
            1: {
                p: "Our app is a reliable assistant for parents who care about their children's speech development. We offer convenient and effective tools that will help your child achieve the best results.",
            },
            2: {
                h3: "Easy to use",
                p: "The app is intuitive, allowing you to easily connect your child and monitor their progress at any time.",
            },
            3: {
                h3: "Individual Plan of Care",
                p: "You can choose exercises for your child yourself, if you do not have a speech therapist, or consult with a speech therapist to choose the most appropriate exercises for your child training program.",
            },
            4: {
                h3: "Audio, Video and photo-screenshots of mistakes",
                p: "Receive audio, video and photos reports to better understand what needs to be worked on.",
            },
            5: {
                h3: "Chat with your speech therapist",
                p: "Receive feedback and recommendations from your speech therapist on correcting and improving exercises.",
            },
            6: {
                h3: "Motivation and support",
                p: "The app motivates the child to study regularly, turning training into an exciting process.",
            },
        },
        chat: "Give your child the best opportunities for speech development with our app!",
    },
    ru: {
        h1: "Приложение для родителей — Поддержка развития речи вашего ребенка",
        mainContent: {
            1: {
                p: "Наше приложение — это надежный помощник для родителей, которые заботятся о развитии речи своих детей. Мы предлагаем удобные и эффективные инструменты, которые помогут вашему ребенку достигать лучших результатов:",
            },
            2: {
                h3: "Легкость в использовании",
                p: "Приложение интуитивно понятно, что позволяет вам легко подключить ребенка и следить за его прогрессом в любое время.",
            },
            3: {
                h3: "Индивидуальная программа упражнений",
                p: "Вы можете самостоятельно выбрать упражнения для вашего ребенка, если у вас нет логопеда, или посоветоваться с логопедом, чтобы подобрать наиболее подходящую программу занятий.",
            },
            4: {
                h3: "Аудио, Видео- и фотофиксация ошибок",
                p: "Получайте отчеты с аудио, видео- и фотофиксацией, чтобы лучше понимать, над чем нужно работать.",
            },
            5: {
                h3: "Обратная связь от логопеда",
                p: "Получайте комментарии и рекомендации от логопеда по коррекции и улучшению упражнений.",
            },
            6: {
                h3: "Мотивация и поддержка",
                p: "Приложение мотивирует ребенка заниматься регулярно, превращая занятие в увлекательный процесс.",
            },
        },
        chat: "Дайте вашему ребенку лучшие возможности для развития речи с помощью нашего приложения!",
    },
};
