"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        h1: "Are you a patient or a speech therapist?",
        buttons: { 1: "Patient", 2: "Speech Therapist" },
    },
    ru: {
        h1: "Вы пациент или логопед?",
        buttons: { 1: "Пациент", 2: "Логопед" },
    },
};
