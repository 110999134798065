"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paragraph = exports.TextBase = exports.H3 = exports.H2 = exports.H1 = void 0;
const H1_1 = require("./H1");
Object.defineProperty(exports, "H1", { enumerable: true, get: function () { return H1_1.H1; } });
const H2_1 = require("./H2");
Object.defineProperty(exports, "H2", { enumerable: true, get: function () { return H2_1.H2; } });
const H3_1 = require("./H3");
Object.defineProperty(exports, "H3", { enumerable: true, get: function () { return H3_1.H3; } });
const Paragraph_1 = require("./Paragraph");
Object.defineProperty(exports, "Paragraph", { enumerable: true, get: function () { return Paragraph_1.Paragraph; } });
const TextBase_1 = require("./TextBase");
Object.defineProperty(exports, "TextBase", { enumerable: true, get: function () { return TextBase_1.TextBase; } });
