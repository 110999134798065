"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepositions = exports.audioFileUrl = exports.AudioPathNames = void 0;
var AudioPathNames;
(function (AudioPathNames) {
    AudioPathNames["Incorrect"] = "incorrect";
    AudioPathNames["Correct"] = "correct";
    AudioPathNames["Diamond"] = "diamond";
    AudioPathNames["Rubin"] = "rubin";
    AudioPathNames["Coin"] = "coin";
    AudioPathNames["ForStar"] = "forStar";
    AudioPathNames["ManyMistakes"] = "manyMistakes";
    AudioPathNames["Normal"] = "normal";
    AudioPathNames["Super"] = "super";
    AudioPathNames["Good"] = "good";
    AudioPathNames["IncorrectSeconds"] = "IncorrectSeconds";
})(AudioPathNames = exports.AudioPathNames || (exports.AudioPathNames = {}));
exports.audioFileUrl = {
    [AudioPathNames.Incorrect]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/incorrect_ru.mp3',
    [AudioPathNames.Correct]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/correct_ru.mp3',
    [AudioPathNames.Diamond]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/Diamond_ru.mp3',
    [AudioPathNames.Rubin]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/Rubin_ru.mp3',
    [AudioPathNames.Coin]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/Coin_ru.mp3',
    [AudioPathNames.ForStar]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/forStar.mp3',
    [AudioPathNames.ManyMistakes]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/Many_ru.mp3',
    [AudioPathNames.Normal]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/Normal_ru.mp3',
    [AudioPathNames.Super]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/Super_ru.mp3',
    [AudioPathNames.Good]: 'https://d9fvxjfzq4088.cloudfront.net/sound_effects/Good_ru.mp3',
    [AudioPathNames.IncorrectSeconds]: 'https://d9fvxjfzq4088.cloudfront.net/sound_errors/ru-error-8.mp3',
};
exports.prepositions = [
    "в", "на", "с", "о", "об", "у", "к", "по", "из", "от", "до",
    "через", "при", "под", "над", "за", "для", "без", "перед",
    "между", "около", "после", "вокруг", "внутри", "вне", "среди"
];
