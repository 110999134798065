"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIPOC = exports.getIPOCSearchParams = void 0;
const react_1 = require("react");
const _params_1 = require("@params");
const getTagList = (ipoc) => ipoc === null || ipoc === void 0 ? void 0 : ipoc.map(exercise => ({
    name: exercise.exercise_name,
    id: exercise.exercise_slug,
    group_slug: exercise.group_slug,
    exercise_id: exercise.exercise
}));
const getIPOCSearchParams = () => {
    // by format /profile_observe/crayzyboy
    const profileObservePath = '/profile_observe/';
    const path = window.location.pathname;
    const patient_username = path.includes(profileObservePath) ? path.replace(profileObservePath, '') : '';
    return patient_username ? '?' + new URLSearchParams({ patient_username }) : '';
};
exports.getIPOCSearchParams = getIPOCSearchParams;
const tagsDefaultValue = [];
const useIPOC = () => {
    const [tags, setTags] = (0, react_1.useState)(tagsDefaultValue);
    const reset = (0, react_1.useCallback)(() => {
        setTags(tagsDefaultValue);
    }, []);
    const changeTags = setTags;
    (0, react_1.useEffect)(() => {
        const searchParams = (0, exports.getIPOCSearchParams)();
        fetch(`${_params_1.apiHost}/get_ipoc${searchParams}`, {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(response => setTags(getTagList(response)));
    }, []);
    return { tags, changeTags, reset };
};
exports.useIPOC = useIPOC;
