"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        h1: "EZSpeech – Your digital speech therapist with Artificial Intelligence",
        imageDesc: {
            1: "Demonstration",
            2: "Pace \nсontrol",
            3: "Animated \ncues",
            4: "Feedback",
            5: "Assessment",
        },
        features: {
            1: "Automatically sets individual parameters depending on the user's capabilities",
            2: "Real-time feedback tailored to specific error made",
            3: "Assessment of \nexercise performance",
            4: "Motivates \nto achieve the goal",
        },
    },
    ru: {
        h1: "EZSpeech – ваш цифровой логопед с искусственным интеллектом",
        imageDesc: {
            1: "Демонстрация",
            2: `Контроль \nтемпа`,
            3: `Анимированная \nподсказка`,
            4: `Обратная связь \n(также голосом)`,
            5: `Оценка \nвыполнения`,
        },
        features: {
            1: "Автоматически подбирает индивидуальные параметры в зависимости от возможностей пользователя",
            2: "Подсказки в режиме реального времени по конкретно совершенной ошибке",
            3: `Оценка правильности \nвыполнения упражнений`,
            4: `Мотивирует \nк достижению цели`,
        },
    },
};
