"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonStyles = void 0;
const buttonPrimaryStyles = {
    borderColor: "#7B4EF8",
    background: "var(--brand-color, #7B4EF8)",
    color: "var(--white, var(--white, #FFF))",
};
const buttonSecondaryStyles = {
    background: "transparent",
    color: "inherit",
    borderColor: "#333",
};
const buttonRoundedStyles = {
    borderColor: "#7B4EF8",
    background: "var(--brand-color, #7B4EF8)",
    color: "var(--white, var(--white, #FFF))",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    borderRadius: "100px",
    padding: "20px",
    margin: "0",
};
exports.buttonStyles = {
    primary: buttonPrimaryStyles,
    secondary: buttonSecondaryStyles,
    rounded: buttonRoundedStyles,
};
