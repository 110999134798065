"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPeriodRange = exports.periodMap = exports.formatDate = exports.toDayMonth = void 0;
const toDayMonth = (dateString) => dateString.split('-').reverse().slice(0, 2).join('.');
exports.toDayMonth = toDayMonth;
const formatDate = date => date.toISOString().substr(0, 10).split('-').reverse().join('.');
exports.formatDate = formatDate;
exports.periodMap = {
    day: 1,
    week: 7,
    month: 30,
    quarter: 120,
    year: 365
};
const getPeriodRange = (value) => {
    const now = Date.now();
    const toDate = new Date(now).toISOString().slice(0, 10);
    const fromDate = new Date(now - exports.periodMap[value] * 86400000).toISOString().slice(0, 10);
    return { fromDate, toDate };
};
exports.getPeriodRange = getPeriodRange;
