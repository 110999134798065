"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
let t;
// @ts-ignore
if (typeof translation === 'undefined')
    t = {
        "articulationTitle": "Articulation",
        "soundTitle": "Sound",
        "motorTitle": "Motor skills",
        "byModules": "By modules",
        "byPeriod": "By period",
        "durationOfExercising": "Duration of exercising",
        "errorListTitle": "Error list",
        "targetLabel": "Target",
        "estimateLabel": "Estimate",
        "min": "min",
        "good": "Good",
        "many_mistakes": "Mistakes",
        "super": "Super",
        "nice_try": "Nice try",
        "year": "Year",
        "month": "Month",
        "week": "Week",
        "averageParameter": "Average Parameters",
        "exerciseTitle": "Exercise",
        "dateTitle": "Date",
        "commonErrorsHead": "Common errors"
    };
// @ts-ignore
else
    t = translation === null || translation === void 0 ? void 0 : translation.charts;
exports.default = t;
