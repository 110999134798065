"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.audioPlayer = void 0;
const audioPathNames_1 = require("@app/enums/audioPathNames");
class AudioPlayer {
    constructor() {
        this.audioElements = {};
        this.currentAudio = null;
        this.preloadAudios();
    }
    preloadAudios() {
        for (const key in audioPathNames_1.audioFileUrl) {
            const audioKey = key;
            const audioUrl = audioPathNames_1.audioFileUrl[audioKey];
            const audio = new Audio(audioUrl);
            audio.load(); // Загрузка аудиофайла
            this.audioElements[audioKey] = audio; // Сохранение в объект
        }
        console.log('Все аудиофайлы предварительно загружены');
    }
    playAudio(key, audioFromBackend) {
        this.stopAudio();
        const audio = audioFromBackend ? new Audio(audioFromBackend) : this.audioElements[key];
        if (!audio) {
            console.error(`Аудиофайл ${key} не найден`);
            return;
        }
        this.currentAudio = audio;
        audio.play().then(() => {
            console.log(`Воспроизведение аудио: ${key}`);
        }).catch(error => {
            console.error(`Ошибка при воспроизведении аудио ${key}:`, error);
        });
    }
    stopAudio() {
        for (const key in this.audioElements) {
            const audio = this.audioElements[key];
            audio.pause();
            audio.currentTime = 0; // Сброс времени воспроизведения на начало
        }
        if (this.currentAudio) {
            this.currentAudio.pause();
            this.currentAudio.currentTime = 0;
        }
        console.log('Все аудиофайлы остановлены');
        this.currentAudio = null;
    }
}
exports.audioPlayer = new AudioPlayer();
